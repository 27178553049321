/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {getImage, GatsbyImage} from 'gatsby-plugin-image';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {Box} = _components;
  if (!Box) _missingMdxReference("Box", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Muutosjohtamiseen tarvitaan parempaa ymmärrystä ja avoimempaa puhetta tunteista!"), "\n", React.createElement(_components.h2, null, "John Fisherin muutoksen käyrä"), "\n", React.createElement(_components.p, null, "John Fisherin muutoksen käyrä (John Fisher's Process of Transition / Fisher’s Curve) kuvaa ihmisten tunnereaktioita ja sopeutumista muutoksiin työssä ja henkilökohtaisessa elämässä. Malli perustuu John M Fisherin vuonna 2012 julkaisemaan tutkimukseen, joka tarkastelee yksilöiden sopeutumista erilaisiin muutoksiin ja pohjaa tunnettuun Kübler-Rossin surun käsittelyn malliin (Kübler-Ross, On Death and Dying, 1969)."), "\n", React.createElement(_components.p, null, "Samalla, kun malli auttaa ymmärtämään tunteiden muuttumista ja kehittymistä, kun yksilöt ja tiimit prosessoivat muutosta, se voi myös tuoda esille miten eri ihmiset voivat olla hyvin eri vaiheissa muutoksen käsittelyssä. Ja jo pelkästään tunteiden sanoittaminenkin voi avata mahdollisuuksia käsitellä niitä rakentavasti."), "\n", React.createElement(_components.p, null, "Vaikka käyrä on yhtenäinen, on tärkeää huomata, että kaikkia eivät käy läpi kaikkia vaiheita ja järjestyskin voi vaihdella."), "\n", React.createElement(Box, {
    textAlign: "center"
  }, React.createElement(GatsbyImage, {
    image: getImage(props.data.mdx.frontmatter.images[0]),
    alt: ""
  })), "\n", React.createElement(_components.h2, null, "SCARF-malli"), "\n", React.createElement(_components.p, null, "SCARF-malli on toinen työkalu, joka voi auttaa ymmärtämään ihmisten sosiaalisia reaktioita tarpeita. Malli perustuu viiteen keskeiseen ulottuvuuteen, jotka vaikuttavat ihmisten kokemukseen ja reaktioihin muutoksessa. SCARF tulee englanninkielisistä sanoista Status (status, asema), Certainty (varmuus, ennustettavuus), Autonomy (autonomia, itsenäisyys), Relatedness (yhteenkuuluvuus, läheisyys) ja Fairness (oikeudenmukaisuus), jotka kuvastavat näitä ulottuvuuksia."), "\n", React.createElement(_components.p, null, "Kyse on sosiaalisista tarpeistamme, jotka painottuvat meillä jokaisella eri tavalla. Tärkeämpää on kuitenkin ymmärtää mitä tapahtuu, mikäli koemme jonkin näistä osa-alueista uhatuksi tai loukatuksi. Vaikka uhka on “vain sosiaalinen”, lopputuloksena saattaa olla fight or flight (or freeze) -reaktio ja sitä kautta oppimisen ja objektiivisuuden heikkeneminen (tai romahtaminen)."), "\n", React.createElement(Box, {
    textAlign: "center"
  }, React.createElement(GatsbyImage, {
    image: getImage(props.data.mdx.frontmatter.images[1]),
    alt: ""
  })), "\n", React.createElement(_components.p, null, "Kannattaa rohkeasti kokeilla vaikka seuraavassa tiimipalaverissa, minkälaista keskustelua näiden kuvien esittäminen herättää."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
